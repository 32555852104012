import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { StripeProvider, Elements } from "react-stripe-elements";

import OrganizationBillingCardForm from "Components/OrganizationBillingCardForm";

const Stripe = ({ publicKey }) => {
  if (!publicKey) {
    return false;
  }

  return (
    <StripeProvider apiKey={publicKey}>
      <Elements>
        <OrganizationBillingCardForm />
      </Elements>
    </StripeProvider>
  );
};

const mapStateToProps = state => ({
  publicKey: state.app && state.app.getIn(["me", "stripe", "public_key"])
});

Stripe.propTypes = {
  publicKey: PropTypes.string
};

export default connect(mapStateToProps)(Stripe);
