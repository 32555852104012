import React from "react";
import PropTypes from "prop-types";

const Card2 = ({ color = "#98a0ab" }) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 15C21.5 15.2761 21.2761 15.5 21 15.5H1C0.723858 15.5 0.5 15.2761 0.5 15V1C0.5 0.723858 0.723858 0.5 1 0.5H21C21.2761 0.5 21.5 0.723858 21.5 1V15Z"
      stroke={color}
    />
    <path d="M21.1875 6H0.1875V4H21.1875V6Z" fill={color} />
  </svg>
);

Card2.propTypes = {
  color: PropTypes.string
};

export default Card2;
