import React from "react";
import PropTypes from "prop-types";

const Visa = ({ color = "#38485E", id = "visa-card" }) => (
  <svg
    width="60"
    height="19"
    viewBox="0 0 60 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath={`url(#clip-${id})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0667 18.2667L24.1333 0.533325H28.9333L25.8667 18.2667H21.0667Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3334 0.933301C42.4 0.533301 40.9333 0.133301 39.0667 0.133301C34.2667 0.133301 30.9334 2.5333 30.9334 5.99997C30.9334 8.5333 33.3333 9.99997 35.2 10.8C37.0667 11.7333 37.7333 12.2666 37.7333 12.9333C37.7333 14.1333 36.2667 14.6666 34.8 14.6666C32.8 14.6666 31.8667 14.4 30.2667 13.7333L29.6 13.4666L28.9333 17.4666C30 18 32.1334 18.4 34.2667 18.4C39.3334 18.4 42.6667 16 42.6667 12.4C42.6667 10.4 41.3334 8.79997 38.6667 7.59997C36.9334 6.79997 36 6.26663 36 5.46663C36 4.66663 36.9334 3.99997 38.8 3.99997C40.4 3.99997 41.4667 4.26663 42.4 4.66663L42.8 4.9333L43.3334 0.933301Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.8667 0.533325H52.1334C50.9334 0.533325 50.1334 0.799992 49.6 1.99999L42.4 18.2667H47.4667C47.4667 18.2667 48.2667 16.1333 48.5334 15.6C49.0667 15.6 54 15.6 54.6667 15.6C54.8 16.2667 55.2 18.2667 55.2 18.2667H59.7334L55.8667 0.533325ZM49.8667 12C50.2667 10.9333 51.7334 7.06666 51.7334 7.06666C51.7334 7.06666 52.1334 5.99999 52.4 5.33333L52.6667 6.79999C52.6667 6.79999 53.6 11.0667 53.7334 11.8667L49.8667 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0668 0.533325L12.2668 12.6667L11.7334 10.1333C10.8001 7.33333 8.13344 4.26666 5.06677 2.66666L9.33344 18.2667H14.4001L22.0001 0.533325H17.0668Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86667 0.533325H0V0.933325C6 2.39999 10.1333 5.99999 11.7333 10.2667L10 1.99999C9.73333 0.933325 8.93333 0.533325 7.86667 0.533325Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id={`clip-${id}`}>
        <rect width="60" height="18.6667" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Visa.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string
};

export default Visa;
