import React from "react";
import PropTypes from "prop-types";

const AmericanExpress = ({ color = "#38485E", id = "amex", inverseColor }) => (
  <svg
    width="60"
    height="22"
    viewBox="0 0 60 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath={`url(#clip-${id})`}>
      <path
        d="M11.6364 21.7273V12.3636H21.5454L22.6364 13.7273L23.7273 12.3636H59.8182V21.0909C59.8182 21.0909 58.9091 21.7273 57.8182 21.7273H37.8182L36.6364 20.2727V21.7273H32.7273V19.1818C32.7273 19.1818 32.1818 19.5455 31 19.5455H29.6364V21.7273H23.6364L22.5454 20.2727L21.4545 21.7273H11.6364Z"
        fill={color}
      />
      <path
        d="M0 5.27273L2.27273 0H6.18182L7.45455 2.90909V0H12.2727L13 2.09091L13.7273 0H35.3636V1.09091C35.3636 1.09091 36.5455 0 38.3636 0H45.3636L46.6364 2.90909V0H50.6364L51.8182 1.63636V0H55.9091V9.45455H51.8182L50.7273 7.81818V9.45455H44.8182L44.1818 8H42.5455L42 9.45455H38C36.3636 9.45455 35.3636 8.36364 35.3636 8.36364V9.45455H29.2727L28.0909 8V9.45455H5.54545L4.90909 7.90909H3.36364L2.72727 9.45455H0V5.27273Z"
        fill={color}
      />
      <path
        d="M3 1.18176L0 8.18176H2L2.54545 6.72722H5.81818L6.36364 8.18176H8.36364L5.36364 1.18176H3ZM4.18182 2.81813L5.18182 5.27267H3.18182L4.18182 2.81813Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M8.54553 8.18176V1.18176H11.3637L13.0001 5.72722L14.5455 1.18176H17.3637V8.18176H15.6364V2.99994L13.7274 8.18176H12.1819L10.2728 2.99994V8.18176H8.54553Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M18.5454 8.18176V1.18176H24.2727V2.72722H20.2727V3.90904H24.1818V5.45449H20.2727V6.72722H24.2727V8.18176H18.5454Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M25.3636 1.18185V8.18185H27.0909V5.72731H27.8182L29.9091 8.18185H32.0909L29.8182 5.54549C30.7273 5.45458 31.7273 4.6364 31.7273 3.36367C31.7273 1.90912 30.5455 1.09094 29.2727 1.09094H25.3636V1.18185ZM27.0909 2.72731H29.0909C29.5455 2.72731 29.9091 3.09094 29.9091 3.45458C29.9091 3.90912 29.4546 4.18185 29.0909 4.18185H27.0909V2.72731Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M34.1818 8.18176H32.3636V1.18176H34.1818V8.18176Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M38.4546 8.18176H38.0909C36.1819 8.18176 35.0909 6.72722 35.0909 4.72722C35.0909 2.63631 36.1819 1.18176 38.5455 1.18176H40.4546V2.81813H38.4546C37.4546 2.81813 36.8182 3.5454 36.8182 4.72722C36.8182 6.09085 37.6364 6.63631 38.7273 6.63631H39.1819L38.4546 8.18176Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M42.2727 1.18176L39.2727 8.18176H41.2727L41.8182 6.81813H45.0909L45.6363 8.18176H47.6363L44.5454 1.18176H42.2727ZM43.4545 2.81813L44.4545 5.27267H42.4545L43.4545 2.81813Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M47.8182 8.18176V1.18176H50.091L52.9091 5.63631V1.18176H54.6364V8.18176H52.4546L49.5455 3.63631V8.18176H47.8182Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M12.8181 20.5455V13.4546H18.5454V15H14.5454V16.1819H18.4545V17.6364H14.5454V18.9091H18.5454V20.4546H12.8181V20.5455Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M40.9091 20.5455V13.4546H46.6363V15H42.7272V16.1819H46.5454V17.6364H42.7272V18.9091H46.7272V20.4546H40.9091V20.5455Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M18.8181 20.5454L21.6363 17.0909L18.8181 13.5454H20.909L22.6363 15.7272L24.3636 13.5454H26.4545L23.6363 17L26.4545 20.5454H24.2727L22.6363 18.3636L20.9999 20.5454H18.8181Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M26.6364 13.5454V20.6363H28.4545V18.3636H30.2727C31.8182 18.3636 33 17.5454 33 15.909C33 14.5454 32.0909 13.5454 30.4545 13.5454H26.6364ZM28.4545 15.0909H30.3636C30.9091 15.0909 31.2727 15.3636 31.2727 15.909C31.2727 16.3636 30.9091 16.7272 30.3636 16.7272H28.4545V15.0909Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M33.8181 13.5454V20.6363H35.5454V18.0909H36.3636L38.4545 20.6363H40.6363L38.3636 18C39.2727 17.909 40.2727 17.0909 40.2727 15.8181C40.2727 14.3636 39.0908 13.5454 37.8181 13.5454H33.8181ZM35.6363 15.0909H37.6363C38.0908 15.0909 38.4545 15.4545 38.4545 15.8181C38.4545 16.2727 37.9999 16.5454 37.6363 16.5454H35.6363V15.0909Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M47.5455 20.5455V19H51.0909C51.6364 19 51.8182 18.7273 51.8182 18.3637C51.8182 18.091 51.6364 17.7273 51.0909 17.7273H49.5455C48.1818 17.7273 47.3636 16.9091 47.3636 15.6364C47.3636 14.5455 48.0909 13.4546 50.0909 13.4546H53.5455L52.7273 15.091H49.7273C49.1818 15.091 49 15.3637 49 15.6364C49 15.9091 49.1818 16.2728 49.6364 16.2728H51.2727C52.8182 16.2728 53.4546 17.1819 53.4546 18.2728C53.4546 19.5455 52.7273 20.5455 51.1818 20.5455H47.5455Z"
        fill={inverseColor ? inverseColor : "white"}
      />
      <path
        d="M53.9999 20.5455V19H57.5454C58.0908 19 58.2727 18.7273 58.2727 18.3637C58.2727 18.091 58.0908 17.7273 57.5454 17.7273H55.9999C54.6363 17.7273 53.8181 16.9091 53.8181 15.6364C53.8181 14.5455 54.5454 13.4546 56.5454 13.4546H59.9999L59.2727 15H56.2727C55.7272 15 55.5454 15.2728 55.5454 15.5455C55.5454 15.8182 55.7272 16.1819 56.1817 16.1819H57.8181C59.3636 16.1819 59.9999 17.091 59.9999 18.1819C59.9999 19.4546 59.2727 20.4546 57.7272 20.4546H53.9999V20.5455Z"
        fill={inverseColor ? inverseColor : "white"}
      />
    </g>
    <defs>
      <clipPath id={`clip-${id}`}>
        <rect width="60" height="21.8182" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

AmericanExpress.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string,
  inverseColor: PropTypes.string
};

export default AmericanExpress;
