import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import CardSection from "./CardSection";

const Layout = styled.div`
  padding: 32px;
  .rccs {
    float: right;
  }
`;

class OrganizationBillingCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
      name: "",
      expiry: "",
      cvc: "",
      focused: ""
    };

    this.onNumberChange = this.onNumberChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onExpiryChange = this.onExpiryChange.bind(this);
    this.onCvcChange = this.onCvcChange.bind(this);
  }

  onNumberChange(e) {
    const number = e.target.value;
    this.setState({
      number: number,
      focused: "number"
    });
  }
  onNameChange(e) {
    const name = e.target.value;
    this.setState({
      name: name,
      focused: "name"
    });
  }
  onExpiryChange(e) {
    const expiry = e.target.value;
    this.setState({
      expiry: expiry,
      focused: "expiry"
    });
  }
  onCvcChange(e) {
    const cvc = e.target.value;
    this.setState({
      cvc: cvc,
      focused: "cvc"
    });
  }

  render() {
    return (
      <Layout>
        <CardSection />
      </Layout>
    );
  }
}

OrganizationBillingCardForm.propTypes = {
  title: PropTypes.string,
  closeForm: PropTypes.func,
  errors: PropTypes.object,
  onSave: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(OrganizationBillingCardForm);
