import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import client from "Libs/platform";

import OrganizationBillingSubNavBar from "Components/OrganizationBillingSubNavBar";
import ContentLayout from "Components/ContentLayout";
import SettingsLayout from "Components/SettingsLayout";
import Heading2 from "Components/styleguide/Heading2";
import ListGroup from "Components/ListGroup";
import Stripe from "Containers/Stripe";
import SettingLine from "Components/SettingLine";
import InputField from "Components/fields/InputField";
import Card2 from "Components/icons/Card2";
import ButtonWrapper from "Components/ButtonWrapper";
import Button from "Components/Button";
import MasterCard from "Icons/cc/MasterCard";
import AmericanExpress from "Icons/cc/AmericanExpress";
import Visa from "Icons/cc/Visa";

const CardForm = styled.form`
  padding: 32px;
  .input-field {
    width: 336px;
  }
  .card-field,
  .cvc-field {
    input,
    input::placeholder {
      letter-spacing: 4px;
    }
  }
  .cvc-field input::placeholder {
    font-weight: 700;
    font-size: 15px;
  }
`;

const InlineFields = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 336px;
  margin-bottom: 32px;
  .input-field {
    width: 152px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  .card {
    height: 56px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9d0e4;
    border-radius: 2px;
  }
  .card + .card {
    margin-left: 8px;
  }
  .active {
    border-color: ${props => props.theme.buttonBg};
    background: ${props => props.theme.buttonBg};
  }
`;

class BillingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardInfo: {
        card_number: "",
        expiry_month: "",
        expiry_year: "",
        cvc: "",
        active_card: ""
      }
    };
    this.updateCard = this.updateCard.bind(this);
    this.updateExpiry = this.updateExpiry.bind(this);
    this.updateCvc = this.updateCvc.bind(this);
  }

  async componentDidMount() {
    const cardOnFile = await client.getCardOnFile();
    this.setState({ cardInfo: cardOnFile.cardonfile });
  }

  updateCard(e) {
    let activeCard = "";
    if (e.target.value.startsWith("4")) {
      activeCard = "visa";
    }
    if (e.target.value.startsWith("55")) {
      activeCard = "mastercard";
    }
    if (e.target.value.startsWith("37")) {
      activeCard = "amex";
    }
    this.setState({
      cardNumber: e.target.value ? e.target.value : "",
      activeCard: activeCard
    });
  }
  updateExpiry(e) {
    this.setState({
      expiry: e.target.value ? e.target.value : ""
    });
  }

  updateCvc(e) {
    this.setState({
      cvc: e.target.value ? e.target.value : ""
    });
  }

  render() {
    const { location, router, params, theme } = this.props;
    return (
      <SettingsLayout>
        <OrganizationBillingSubNavBar
          pathname={location.pathname}
          push={router.push}
          organizationId={params.organizationId}
        />
        <ContentLayout id="settings-members" className="settings-content">
          <Heading2 id="credit-card-heading">Payment details</Heading2>
          <SettingLine
            isOpen={true}
            info={
              <span>
                <strong>Credit card</strong>
              </span>
            }
            noToggle={true}
          >
            <CardForm>
              <CardWrapper>
                <div
                  className={`card${
                    this.state.activeCard === "mastercard" ? " active" : ""
                  }`}
                >
                  <MasterCard
                    color={
                      this.state.activeCard === "mastercard"
                        ? "#ffffff"
                        : "#38485e"
                    }
                  />
                </div>
                <div
                  className={`card${
                    this.state.activeCard === "visa" ? " active" : ""
                  }`}
                >
                  <Visa
                    color={
                      this.state.activeCard === "visa" ? "#ffffff" : "#38485e"
                    }
                  />
                </div>
                <div
                  className={`card${
                    this.state.activeCard === "amex" ? " active" : ""
                  }`}
                >
                  <AmericanExpress
                    color={
                      this.state.activeCard === "amex" ? "#ffffff" : "#38485e"
                    }
                    inverseColor={
                      this.state.activeCard === "amex"
                        ? theme && theme.buttonBg
                        : ""
                    }
                  />
                </div>
              </CardWrapper>
              <InputField
                label="Credit card number"
                className="card-field"
                placeholder={this.state.cardInfo.card_number}
                onChange={this.updateCard}
                icon={<Card2 />}
                iconAlign="left"
              />
              <InlineFields>
                <InputField
                  label="Expiry date"
                  className="expiry-field"
                  placeholder={`${this.state.cardInfo.exp_month} / ${
                    this.state.cardInfo.exp_year
                  }`}
                  // mask="MM / YY"
                  // maskChar=" / "
                  onChange={this.updateExpiry}
                />
                <InputField
                  label="CVC"
                  className="cvc-field"
                  placeholder="***"
                  value={this.state.cvc}
                  onChange={this.updateCvc}
                />
              </InlineFields>
              <ButtonWrapper>
                <Button type="submit">Save</Button>
                <Button className="secondary" type="submit">
                  Cancel
                </Button>
              </ButtonWrapper>
            </CardForm>
          </SettingLine>
          <ListGroup aria-labelledby="credit-card-heading">
            <Stripe />
          </ListGroup>
        </ContentLayout>
      </SettingsLayout>
    );
  }
}

BillingCard.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  theme: PropTypes.object
};

export default withTheme(BillingCard);
